import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Title from "../components/title";

const faireUnDon = () => {
  const { file } = useStaticQuery(query);
  const image = getImage(file.childImageSharp.gatsbyImageData) as IGatsbyImageData;
  const seoImage = image
    ? {
        width: image.width,
        height: image.height,
        src: image?.images?.fallback?.src
      }
    : null;

  return (
    <Layout>
      <Seo title="Faire un don" description="Soutenir Veille Sur Ton Coeur" image={seoImage} />
      <section className="page about-page">
        <div
          className="section-center about-center project"
          style={{
            marginBottom: "4rem",
            direction: "rtl",
            alignItems: "start"
          }}
        >
          <div className="about-img">
            <GatsbyImage image={image} alt="Faire un don" />
          </div>
          <article
            className="about-text"
            style={{
              direction: "initial"
            }}
          >
            <Title title="Faire un don" />
            <div>
              <p>
                Depuis la création de notre ministère, Dieu nous fait la grâce de voir des cœurs qui sont touchés et des
                vies qui sont transformées. Non seulement nous atteignons plus d’âmes, mais notre équipe s’agrandit
                également.
              </p>
              <p>
                Ton don nous permettra, entre autres, de continuer à croître dans notre mission d’aider à la guérison
                émotionnelle et spirituelle des femmes, ainsi qu’à l’affirmation de leur identité en Christ. Il nous
                permettra également de donner vie à plusieurs projets qui te béniront certainement.
              </p>
              <p>
                Nous te remercions infiniment de choisir de nous soutenir. Ensemble, équipons-nous les uns les autres
                pour devenir des serviteurs de Dieu émotionnellement sains et spirituellement matures.
              </p>
              <p style={{ fontWeight: "bold" }}>
                P.S. Si tu vis au Canada, nous t’invitons à faire ton don par virement Interac.{" "}
                <ContactLink to="/contact/">Écris-nous</ContactLink>
                {""} pour obtenir plus de détail.
              </p>
            </div>
            <DonNote>
              En cliquant sur l'un des boutons ci-dessous, tu seras redirigé vers Square ou PayPal pour faire ton don.
            </DonNote>
            <DonateContainer>
              <PayPalLink
                href="https://www.paypal.com/donate/?hosted_button_id=SMPMTJLEZPA6G"
                target="_blank"
                role="button"
              >
                Donner via PayPal
              </PayPalLink>
              <SquareLink href="https://square.link/u/wnd5XmXZ" target="_blank" role="button">
                Donner via Square
              </SquareLink>
            </DonateContainer>
          </article>
        </div>
      </section>
    </Layout>
  );
};

export default faireUnDon;

const query = graphql`
  {
    file(sourceInstanceName: { eq: "images" }, name: { eq: "donate" }) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF], quality: 60)
      }
    }
  }
`;

const DonateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
`;

const DonateButton = styled.a`
  border-radius: 5px;
  color: white;
  padding: 0.5em;
  text-decoration: none;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background: var(--clr-primary-5);
    transition: 0.3s;
    border-color: var(--clr-primary-5);
    color: var(--clr-white);
  }
`;

const PayPalLink = styled(DonateButton)`
  background: #3b7bbf;
`;

const SquareLink = styled(DonateButton)`
  background: var(--clr-white);
  border: 2px solid var(--clr-grey-1);
  color: var(--clr-grey-1);
`;

const DonNote = styled.small`
  &::before {
    content: "*";
  }
`;

const ContactLink = styled(Link)`
  text-decoration: underline;
  color: var(--clr-primary-8);
  &:hover {
    color: var(--clr-primary-5);
  }
`;
